import { AxiosInstance } from 'axios';
import { dashboardWP } from '../services/api';
import { BlogPost } from '../types/Blog';

export interface BlogRepositoryInterface {
  getAllBlogPosts(): Promise<BlogPost[]>;
  getBlogPost(slug: string): Promise<any>;
}

export class BlogRepository implements BlogRepositoryInterface {
  
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

 async getBlogPost(slug: string): Promise<any> {
  try {
    const response = await this.api.get(`/posts?slug=${slug}`);
    const { data } = response;

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Invalid blog data');
    }

    const post = data[0];

    return post;
  } catch (error) {
    console.error('Error getting blog post:', error);
    return null;
  }
}

  async getAllBlogPosts(): Promise<BlogPost[]> {
    try {
      const response = await this.api.get<BlogPost[]>('posts?_embed');
      const blogData = response.data;

      if (!blogData || !Array.isArray(blogData)) {
        throw new Error('Invalid blog data');
      }

      const blogPostsArray: BlogPost[] = blogData;

      return blogPostsArray;
    } catch (error) {
      console.error('Error getting blog posts:', error);
      return [];
    }
  }
}

export const blogRepository = new BlogRepository(dashboardWP);
