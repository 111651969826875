import styles from "./styles.module.scss";
import { useState } from "react";

const Contact: React.FC = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };
  return (
    <>
      <section className={styles.containerContato}>
        <div className={styles.conteudo}>
          <div className={styles.contato}>
            <h1>Entre em contato com nossa equipe</h1>
            <a href="mailto:learninglab@ufc.br">
              <img src="/images/icone_email_branco.svg" alt="icone de email" />
              learninglab@ufc.br
            </a>
            <a href="#/">
              <img
                src="/images/icone_telefone_branco.svg"
                alt="icone de telefone"
              />
              (88) 3411-2143
            </a>
            <div className={styles.midiasSociais}>
              <a
                href="https://www.instagram.com/learninglabufc/"
                target="_blank" rel="noreferrer"
              >
                <img
                  src="/images/icone_instagram_branco.svg"
                  alt="icone do instagram"
                />
                @learninglabufc
              </a>
              <a
                href="https://www.linkedin.com/company/projeto-learninglab/"
                target="_blank" rel="noreferrer"
              >
                <img
                  src="/images/icone_linkedin_branco.svg"
                  alt="icone do linkedin"
                />
                LearningLab
              </a>
            </div>
          </div>
          <div className={styles.formulario}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Digite seu nome"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Seu melhor e-mail para contato"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                placeholder="Escreva sua mensagem, sugestões, dúvida..."
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit">Enviar mensagem</button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;