import styles from "./styles.module.scss";

interface CardProps {
  imageSrc: string;
  title: string;
  description: string;
  buttonText: string;
}

const CardHorizontal: React.FC<CardProps> = ({
  imageSrc,
  title,
  description,
  buttonText,
}) => {
  return (
    <div className={styles.card}>
      <img src={imageSrc} alt={title} className={styles.image} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: description || "" }} />
        <button className={styles.button}>{buttonText}</button>
      </div>
    </div>
  );
};

export default CardHorizontal;
