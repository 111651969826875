import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const Footer: React.FC = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className={styles.footer}>
      <Link to="/">
        <div className={styles.ativo}>
          <img
            src="/images/logo-colorida.png"
            alt="imagem logo"
            className={styles.logo}
            width={170}
            height={110}
          />
        </div>
      </Link>
      <div className={styles.container}>
        <div>
          <h4>Sobre</h4>
          <ul>
            <li>
              <Link to="/" style={{ textDecoration: "none" }}>
                Nossa equipe
              </Link>
            </li>
            <li>
              <Link to="/blog" style={{ textDecoration: "none" }}>
                Iniciativas
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Redes sociais</h4>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/learninglabufc/"
                target="_blank" rel="noreferrer"
              >
                <img
                  src="/images/icone_insta.png"
                  alt="icone instagram"
                  className={styles.iconSocialMedia}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/projeto-learninglab/"
                target="_blank" rel="noreferrer"
              >
                <img
                  src="/images/icone-linkedin-verde.svg"
                  alt="icone linkedin"
                  className={styles.iconSocialMedia}
                />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4>Contato</h4>
          <ul>
            <li>
              <img src="/images/icone_email.png" alt="icone email" />
              <p>learninglab@ufc.br</p>
            </li>
            <li>
              <img src="/images/icone_tel.png" alt="icone telefone" />
              <p>(88) 3411-2143</p>
            </li>
          </ul>
        </div>
        <div>
          <h4>Alguns dos nossos cursos</h4>
          <ul>
            <li>SCRUM na prática</li>
            <li>Desenvolvimento web</li>
            <li>Git e Github</li>
            <li>Código limpo</li>
            <li>Figmatizando ideias</li>
          </ul>
        </div>
        <div>
          <ul>
            <Link to="/">
              <img
                src="/images/icone-page-inicial.svg"
                alt="imagem logo"
                className={styles.logo}
                width={50}
                height={70}
              />
            </Link>
          </ul>
        </div>
      </div>
      <p>Todos os direitos reservados. {year}.</p>
    </footer>
  );
};

export default Footer;
