"use client";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  EffectCube,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import { postsLinks } from "../../utils/postLinks";

interface SliderProps {
  blog: any;
}

const SectionLecture: React.FC<SliderProps> = ({ blog }) => {
  const [navigationEnabled, setNavigationEnabled] = useState(true);
  const [isClient, setIsClient] = useState(false);

  const updateNavigation = () => {
    if (window.innerWidth <= 640) {
      setNavigationEnabled(false);
    } else {
      setNavigationEnabled(true);
    }
  };

  useEffect(() => {
    setIsClient(true);
    updateNavigation();

    window.addEventListener("resize", updateNavigation);

    return () => {
      window.removeEventListener("resize", updateNavigation);
    };
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <section className={styles.containerLeitura}>
      <div className={styles.backgroundContainerLeitura}></div>
      <div className={styles.frameLecture}>
        <div className={styles.moreNews}>
          <h2>Para leitura</h2>
          <button>
            <Link to="/blog">
              <div className={styles.ativo}>Ler mais notícias</div>
            </Link>
          </button>
        </div>

        <Swiper
          className={styles.swiper}
          autoplay={{ delay: 5000 }}
          modules={[Autoplay, Navigation, Pagination, Scrollbar, EffectCube]}
          navigation={navigationEnabled}
          spaceBetween={50}
          pagination={{ clickable: true }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            760: {
              slidesPerView: 2,
            },
            940: {
              slidesPerView: 3,
            },
          }}
        >
          <div className={styles.slideContent}>
            {blog.map((post: any) => (
              <SwiperSlide className={styles.cardNews} key={post.slug}>
                <img
                  src={
                    post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || ""
                  }
                  alt=""
                  width={500}
                  height={180}
                  className={styles.image}
                />
                <div className={styles.descriptionNews}>
                  <h3>{post.title.rendered}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                  />

                  <Link to={`/blog/${post.slug}`}>
                    <button>Ler mais</button>
                  </Link>
                </div>
              </SwiperSlide>
            ))}

            {postsLinks.map((post, key) => (
              <SwiperSlide className={styles.cardNews} key={key}>
                <img
                  src={post.imageSrc}
                  alt=""
                  width={500}
                  height={180}
                  className={styles.image}
                />
                <div className={styles.descriptionNews}>
                  <h3>{post.title}</h3>
                  <p>{post.description}</p>

                  <Link to={post.link} target="_blank">
                    <button>Ler mais</button>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default SectionLecture;
