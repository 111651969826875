import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { BlogPost } from "../../types/Blog";
import { blogRepository } from "../../repositories/BlogRepository";

interface BlogState {
  posts: BlogPost[];
  loading: boolean;
  error: string | null;
}

const initialState: BlogState = {
  posts: [],
  loading: false,
  error: null,
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    fetchPostsRequest(state) {
      state.loading = true; 
      state.error = null;
    },
    fetchPostsSuccess(state, action: PayloadAction<BlogPost[]>) {
      state.loading = false;
      state.posts = action.payload;
    },
    fetchPostsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  
});

export const fetchPosts = () => async (dispatch: AppDispatch) => {
  dispatch(fetchPostsRequest());
  try {
    const blog = await blogRepository.getAllBlogPosts();
    dispatch(fetchPostsSuccess(blog));
  } catch (error) {
    dispatch(fetchPostsFailure("Erro ao carregar posts"));
  }
};

export const { fetchPostsRequest, fetchPostsSuccess, fetchPostsFailure } = blogSlice.actions;
export default blogSlice.reducer;
