import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchPage } from "../../redux/slices/pageSlice";

const ContainerAboutCourses: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { page, error } = useSelector((state: RootState) => state.pages);

  useEffect(() => {
    dispatch(fetchPage(305));
  }, [dispatch]);

  if (error) {
    return <div>Ocorreu um erro ao carregar o container nosso cursos.</div>;
  }

  return (
    <>
      {page && (
        <>
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </>
      )}
    </>
  );
};

export default ContainerAboutCourses;
