import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Post from "./pages/Blog/slug";
import Layout from "./Layout";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Sobre from "./pages/Sobre";
import Courses from "./pages/Cursos";
import Membros from "./pages/Membros";
import Contact from "./pages/Contato";
import Gamification from "./pages/Gameficacao";
import Artigos from "./pages/Artigos";
import Intensive from "./pages/Gameficacao/intensive";
import EEEP from "./pages/Gameficacao/eeep";
import Sescomp from "./pages/Gameficacao/sescomp";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<Post />} />
          <Route path="/artigos" element={<Artigos />} />
          <Route path="/cursos" element={<Courses />} />
          <Route path="/membros" element={<Membros />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/gameficacao" element={<Gamification />} />
          <Route path="/gameficacao-intensivao" element={<Intensive />} />
          <Route path="/gameficacao-eep" element={<EEEP />} />
          <Route path="/gameficacao-sescomp" element={<Sescomp />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
