import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { BlogPost } from "../../types/Blog";
import { pagesRepository } from "../../repositories/PagesRepository";

interface PageState {
  page: BlogPost | null;
  loading: boolean;
  error: string | null;
}

const initialState: PageState = {
  page: null,
  loading: false,
  error: null,
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    fetchPageRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPageSuccess(state, action: PayloadAction<BlogPost>) {
      state.loading = false;
      state.page = action.payload;
    },
    fetchPageFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  
});

export const fetchPage = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(fetchPageRequest());
  try {
    const pages = await pagesRepository.getPage(id);
    dispatch(fetchPageSuccess(pages));
  } catch (error) {
    dispatch(fetchPageFailure("Erro ao carregar page"));
  }
};

export const { fetchPageRequest, fetchPageSuccess, fetchPageFailure } = pageSlice.actions;
export default pageSlice.reducer;
