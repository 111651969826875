import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { AppDispatch, RootState } from "../../redux/store";
import { useEffect } from "react";
import { fetchPage } from "../../redux/slices/pageSlice";

const About: React.FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const { page, error } = useSelector((state: RootState) => state.pages);

  useEffect(() => {
    dispatch(fetchPage(295));
  }, [dispatch]);

  if (error) {
    return <div>Ocorreu um erro ao carregar os membros.</div>;
  }
  
  return (
    <>
      <section className={styles.containerAboutUs}>
        <div className={styles.content}>
          <h1>{page?.title.rendered}</h1>
        </div>
      </section>
      {page && (
        <>
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </>
      )}
    </>
  );
};

export default About;

