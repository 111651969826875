import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlogPost } from "../../../types/Blog";
import { blogRepository } from "../../../repositories/BlogRepository";
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";
import styles from "./styles.module.scss";

const Post: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await blogRepository.getBlogPost(slug!);
        setPost(fetchedPost);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);

  if (!post) {
    return <div>Carregando...</div>;
  }

  return (
    <main className={styles.container}>
      <article className={styles.post}>
        <h1>{post.title.rendered}</h1>
        <time>{new Date(post.date).toLocaleDateString()}</time>
        <div className={styles.postContent}>
          <div
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
        </div>
      </article>
    </main>
  );
};

export default Post;
