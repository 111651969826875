import React from "react";
import Providers from "./redux/provider";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
        rel="stylesheet"
      ></link>
      <Providers>
        <Header />
        {children}
        <Footer />
      </Providers>
    </div>
  );
};

export default Layout;
