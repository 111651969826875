import React, { CSSProperties, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";

const Header: React.FC = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const isActive = (page: string) => {
    return location.pathname === page ? styles.ativo : "";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const navbarStyles: CSSProperties = {
    backgroundColor:
      location.pathname === "/" ||
      location.pathname === "/gameficacao" ||
      location.pathname === "/gameficacao-intensivao" ||
      location.pathname === "/gameficacao-eep" ||
      location.pathname === "/gameficacao-sescomp" ||
      location.pathname === "/artigos"
        ? "rgb(242, 99, 126)"
        : "rgb(0, 154, 154)",
  };

  return (
    <div className={styles.cabecalho} style={navbarStyles}>
      <header className={styles.headerContainer}>
        <Link to="/" aria-current="page" onClick={closeMenu}>
          <img
            src="/images/logo.png"
            alt="logo"
            className={styles.logoHeader}
          />
        </Link>

        <div className={styles.iconeMenuMobile} onClick={toggleMenu}>
          {menuOpen ? (
            <div className={styles.closeIcon}>X</div>
          ) : (
            <>
              <span></span>
              <span></span>
              <span></span>
            </>
          )}
        </div>

        <nav>
          <ul
            className={`${styles.navMenu} ${menuOpen ? styles.menuOpen : ""}`}
          >
            <li className={styles.menuItem}>
              <Link
                to="/sobre"
                className={isActive("/sobre")}
                aria-current="page"
                onClick={closeMenu}
              >
                Sobre
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/blog"
                className={isActive("/blog")}
                aria-current="page"
                onClick={closeMenu}
              >
                Blog
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/artigos"
                className={isActive("/artigos")}
                aria-current="page"
                onClick={closeMenu}
              >
                Artigos
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/cursos"
                className={isActive("/cursos")}
                aria-current="page"
                onClick={closeMenu}
              >
                Cursos
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/membros"
                className={isActive("/membros")}
                aria-current="page"
                onClick={closeMenu}
              >
                Membros
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/contato"
                className={isActive("/contato")}
                aria-current="page"
                onClick={closeMenu}
              >
                Contato
              </Link>
            </li>
            <li>
              <a className={styles.linkGamificacao}>Gamificação</a>
              <ul className={styles.submenu}>
                <li>
                  <Link
                    to="/gameficacao"
                    className={isActive("/gameficacao")}
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Gamificação Estadual
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gameficacao-intensivao"
                    className={isActive("/gameficacao-intensivao")}
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Gamificação Intensivão
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gameficacao-eep"
                    className={isActive("/gameficacao-eep")}
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Gamificação EEEP
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gameficacao-sescomp"
                    className={isActive("/gameficacao-sescomp")}
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Gamificação Sescomp
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
