import styles from "./styles.module.scss";

const Intensive: React.FC = () => {
  return (
    <>
      <section className={styles.containerGamefication}>
        <div className={styles.content}>
          <h1>GAMIFICAÇÃO INTENSIVÃO</h1>
        </div>
      </section>
      <section className={styles.introduction}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h2 className={styles.subtitle}>
              QUER GANHAR PRÊMIOS PARTICIPANDO DO INTENSIVÃO LEARNINGLAB?
            </h2>
            <p>Olá Learnian@!</p>
            <p>
              Queremos presentear você, Learnian@ fiel. Elaboramos um sistema de
              gamificação para premiar sua participação em nossas atividades
              durante o intensivão do LearningLab.
            </p>
            <p>
              Participe de todas as nossas atividades, para acumular pontos e
              trocar por brindes, consulte a tabela.
            </p>
            <h2 className={styles.subtitle}>O CARTÃO FIDELIDADE</h2>
            <p>
              Antes de começar, resgate nosso cartão fidelidade na nossa sala do
              projeto (no Bloco 4 - do R.U), nas atividades ou no stand. Mas
              atenção, adote apenas um cartão para facilitar sua jornada!
            </p>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>O QUE FAZER PARA GANHAR PONTOS?</h2>
          <ul>
            <li>Participar dos cursos do LearningLab no Intensivão;</li>
            <li>Realizar a atividade final de um curso;</li>
            <li>Seguir o LearningLab no Instagram;</li>
            <li>Postar 3 stories e marcar o projeto.</li>
          </ul>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <table className={styles.tg}>
            <thead>
              <tr>
                <th className={styles.tgUzvj}>ATIVIDADE</th>
                <th className={styles.tg7Btt} colSpan={2}>
                  RECOMPENSAS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.tgC3ow}>
                  Seguir o perfil oficial do LearningLab no Instagram
                </td>
                <td className={styles.tgC3ow}>1 selo rosa</td>
                <td className={styles.tg9wq8} rowSpan={3}>
                  1 selo #tôONLL <br />
                  (o resgate só ocorre com 3 selos rosas)
                </td>
              </tr>
              <tr>
                <td className={styles.tgC3ow}>
                  Postar 3 stories relacionados ao projeto LearningLab e marcar
                  o projeto
                </td>
                <td className={styles.tg0Pky}>1 selo rosa</td>
              </tr>
              <tr>
                <td className={styles.tg9wq8}>
                  Comentar no post de registros do Intensivão
                </td>
                <td className={styles.tg0Pky}>
                  1 selo cores e <br />1 selo rosa
                </td>
              </tr>
              <tr>
                <td className={styles.tgC3ow}>Participar de dois cursos</td>
                <td className={styles.tgC3ow}>1 selo amarelo</td>
                <td className={styles.tg9wq8} rowSpan={3}>
                  1 selo #Aventureir@LL
                  <br />
                  (resgate com 3 selos amarelos)
                </td>
              </tr>
              <tr>
                <td className={styles.tgBaqh}>
                  Fazer o projeto final de um curso
                </td>
                <td className={styles.tgBaqh}>1 selo amarelo</td>
              </tr>
              <tr>
                <td className={styles.tgBaqh}>Dar feedback no Instagram</td>
                <td className={styles.tgBaqh}>1 selo amarelo</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>O PRÊMIO</h2>
          <div className={styles.text}>
            <div className={styles.subtext}>
              <p>
                <strong>1 kit guloseima (pipoca, bombom, pirulito)</strong>
              </p>
              <p>
                <strong>1 eco copo personalizado</strong>
              </p>
              <p>
                <strong>1 bottom personalizado</strong>
              </p>
            </div>
            <p>{">"} O resgate deve ser feito após o IntensivãoLL</p>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>REGRAS IMPORTANTES</h2>
          <ul>
            <li>
              <strong>Promoção válida durante o IntensivãoLL;</strong>
            </li>
            <li>
              Apenas <strong>um resgate por participante;</strong>
            </li>
            <li>
              Mantenha seu cartão fidelidade seguro,{" "}
              <strong>não nos responsabilizamos por perdas;</strong>
            </li>
            <li>
              Pontos só são distribuídos no <strong>final do curso.</strong>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Intensive;
