import { configureStore } from '@reduxjs/toolkit';
import blogReducer from './slices/blogSlice';
import pageReducer from './slices/pageSlice';

export const store = configureStore({
  reducer: {
    blog: blogReducer,
    pages: pageReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;