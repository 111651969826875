import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts } from "../../redux/slices/blogSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { postsLinks } from "../../utils/postLinks";
import { Link } from "react-router-dom";
import PostCard from "../../components/PostCard";
import CardHorizontal from "../../components/CardHorizontal";
import styles from "./styles.module.scss";

const Blog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { posts, error } = useSelector(
    (state: RootState) => state.blog
  );

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <main className={styles.main}>
      <section className={styles.containerHeader}>
        <div className={styles.content}>
          <h1>Blog</h1>
        </div>
      </section>

      <div className={styles.container}>
        <div className={styles.cardContainer}>
          {posts.length > 0 && (
            <Link to={`/blog/${posts[0].slug}`} key={posts[0].slug}>
              <CardHorizontal
                imageSrc={
                  posts[0]._embedded?.["wp:featuredmedia"]?.[0]?.source_url ||
                  ""
                }
                title={posts[0].title.rendered}
                description={posts[0].excerpt.rendered}
                buttonText="Ver mais"
              />
            </Link>
          )}
          {posts.slice(1).map((post: any) => (
            <Link
              to={post.slug === "artigos" ? "/artigos" : `/blog/${post.slug}`}
              key={post.slug}
            >
              <PostCard
                imageUrl={
                  post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || ""
                }
                title={post.title.rendered}
                description={post.excerpt.rendered}
                titleButton="Ver mais"
              />
            </Link>
          ))}

          {postsLinks.map((post, key) => (
            <Link to={post.link} key={key} target="_blank">
              <PostCard
                imageUrl={post.imageSrc}
                title={post.title}
                description={post.description}
                titleButton="Ver mais"
              />
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Blog;
