import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  EffectFlip,
} from "swiper/modules";
import styles from "./styles.module.scss";

const comments = [
  {
    text: "Achei bem interessante, me abriu mais a mente em relação ao assunto, assim como o interesse, e consegui abstrair bastante conteúdo. Seria massa um 2.0 desse curso.",
    author: "Davi Gomes",
  },
  {
    text: "Olá, o curso foi ótimo e acho que fiz bom proveito de todo o conteúdo dado, pena que os dispositivos do laboratório não cooperaram, mas foi muito bom na medida do possível. Foi ótimo quando implementaram a ferramenta web.",
    author: "Ruan Pablo",
  },
  {
    text: "O curso é ótimo, sempre é bom estar aprimorando os conhecimentos e conhecendo novas tecnologias. Nesses 5 dias, me fez entender melhor como o Flutter e o Dart trabalham no mercado das tecnologias.",
    author: "Rayan Victor",
  },
  {
    text: "O curso foi excelente, o professor tem grande conhecimento e didática, além das aulas serem super práticas e direto ao ponto.",
    author: "Wesley Jonatha",
  },
  {
    text: "Gostei muito da espontaneidade dos professores, da suas didáticas e principalmente do aprendizado que eu obtive.",
    author: "Antonio Kawn",
  },
  {
    text: "O curso me mostrou uma tecnologia muita utilizada no mercado, como ela funciona e o seu resultado.",
    author: "Arthur Levi",
  },
  {
    text: "Eu achei o curso ótimo, aprendi demais com todos os alunos que dirigiram as aulas, com diversas formas de apresentar o conteúdo. O projeto final foi essencial pra colocar tudo que vimos no curso em prática. Obrigada a todos os envolvidos e que vocês possam ir longe com o projeto.",
    author: "Nathalya Cardoso",
  },
  {
    text: "Curti demais, achei sensacional, não só a parte das explicações, mas também a ajuda que era dada para quem estivesse com alguma dificuldade. Todos que explicaram, conseguiram transmitir bem o conteúdo e foi muito bom os últimos dois dias, por colocar em prática tudo que nos foi ensinado. Boa sorte para a equipe, vocês são feras!!!",
    author: "Pedro Henrique",
  },
];

const CommentsContainer: React.FC = () => {
  return (
    <>
      <h1 className={styles.title}>
        O que comentam sobre nós <div> ❤ </div>
      </h1>
      <Swiper
        autoplay={{ delay: 5000 }}
        effect={"flip"}
        modules={[
          EffectFlip,
          Autoplay,
          Navigation,
          Pagination,
          Scrollbar,  
        ]}
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation
        className={styles.swiper}
      >
        {comments.map((comment, index) => (
          <SwiperSlide key={index} className={styles.slide}>
            <div className={styles.card}>
              <div className={styles.cardContent}>
                <p className={styles.cardText}>{comment.text}</p>
                <p className={styles.username}>{comment.author}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CommentsContainer;
