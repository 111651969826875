export const postsLinks = [
    {
      title: "O fim ou uma nova fase?",
      description: "Conheça a nova identidade visual do projeto LearningLab",
      imageSrc:
        "https://dashboard.learninglab.com.br/wp-content/uploads/2024/11/novaidentidade.png",
      link: "https://www.instagram.com/p/CkLmNgbp_Am/",
    },
    {
      title: "A nova fase do LearningLab",
      description:
        "Estamos de cara nova!!!! 🥳😍🥰 Iniciamos uma nova fase por aqui, vocês estão preparad@s?",
      imageSrc:
        "https://dashboard.learninglab.com.br/wp-content/uploads/2024/11/novascoresid.png",
      link: "https://www.instagram.com/p/CkOK4OouSKv/",
    },
    {
      title: "Conheça nossa vila learniana",
      description:
        "Agora vamos iniciar uma nova série, venha conhecer a vila learnina.",
      imageSrc:
        "https://dashboard.learninglab.com.br/wp-content/uploads/2024/11/ep1.jpeg",
      link: "https://www.instagram.com/reel/CkPI7i4JvHf/?utm_source=ig_web_copy_link",
    },
    {
      title: "LearningLab é indicado ao Top Awards e vence em 4 categorias.",
      description:
        "Ficamos muito felizes em anunciar que fomos indicados a 4 categorias do prêmio TOP AWARDS 🥳🥳",
      imageSrc:
        "https://dashboard.learninglab.com.br/wp-content/uploads/2024/11/premio.png",
      link: "https://www.instagram.com/p/CYtihdcOeAi/",
    },
    {
      title:
        "Momentos do nosso primeiro curso presencial: Figma para iniciantes",
      description:
        "O Figmatizando ideias 2.0 vem aí e em breve muitas novidades!",
      imageSrc:
        "https://dashboard.learninglab.com.br/wp-content/uploads/2024/11/cursoFigma.jpeg",
      link: "https://www.instagram.com/p/Cfc0tkQuD-1/",
    },
  ];