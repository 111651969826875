import styles from "./styles.module.scss";

const Sescomp: React.FC = () => {
  return (
    <>
      <section className={styles.containerGamefication}>
        <div className={styles.content}>
          <h1>GAMIFICAÇÃO SESCOMP</h1>
        </div>
      </section>
      <section className={styles.introduction}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h2 className={styles.subtitle}>
              QUER GANHAR PRÊMIOS ASSISTINDO NOSSAS ATIVIDADES NA SESCOMP?
            </h2>
            <p>Olá Learnian@!</p>
            <p>
              Queremos presentear você, Learnian@ fiel. Elaboramos um sistema de
              gameficação para premiar sua participação em nossas atividades
              durante a SESCOMP.
            </p>
            <p>
              Participe de todas as nossas atividades, para você acumular pontos
              e trocar por brindes, consulte a tabela.
            </p>
            <h2 className={styles.subtitle}>O CARTÃO FIDELIDADE</h2>
            <p>
              Antes de começar você precisa resgatar nosso cartão fidelidade.
              Você consegue pegar o cartão na nossa sala do projeto (no Bloco 4
              - do R.U), nas atividades ou no stand. Mas atenção, adote apenas
              um cartão para facilitar na sua jornada!
            </p>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <ul>
            <li>
              Participando das atividades (minicurso/palestra) do LearningLab na
              SESCOMP;
            </li>
            <li>Visitar o stand do LearningLab;</li>
            <li>Seguir o LearningLab no Instagram;</li>
            <li>Postar 3 stories e marcar o projeto.</li>
          </ul>
        </div>
      </section>

      <section className={styles.table}>
        <div className={styles.content}>
          <table className={styles.tg}>
            <thead>
              <tr>
                <th className={styles.tgUzvj}>ATIVIDADE</th>
                <th className={styles.tg7btt} colSpan={2}>
                  RECOMPENSAS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.tgC3ow}>
                  Seguir o perfil oficial do LearningLab no Instagram
                </td>
                <td className={styles.tgC3ow}>1 selo rosa</td>
                <td className={styles.tg9wq8} rowSpan={3}>
                  1 selo #tôONLL (o resgate desse selo só ocorre com a
                  acumulação de três selos rosas)
                </td>
              </tr>
              <tr>
                <td className={styles.tgC3ow}>
                  Postar 3 stories relacionados ao projeto LearningLab na
                  SESCOMP e marcar o projeto
                </td>
                <td className={styles.tg0pky}>1 selo rosa</td>
              </tr>
              <tr>
                <td className={styles.tg9wq8}>Passar no stand do projeto</td>
                <td className={styles.tg0pky}>1 selo cores e 1 selo rosa</td>
              </tr>
              <tr>
                <td className={styles.tgC3ow}>
                  Ir para o minicurso Descomplicando o Figma
                </td>
                <td className={styles.tgC3ow}>1 selo amarelo</td>
                <td className={styles.tg9wq8} rowSpan={3}>
                  1 selo #Aventureir@LL (o resgate desse selo só ocorre com a
                  acumulação de três selos amarelos)
                </td>
              </tr>
              <tr>
                <td className={styles.tgBaqh}>
                  Ir para a palestra Trajetória de sucesso do LearningLab
                </td>
                <td className={styles.tgBaqh}>1 selo amarelo</td>
              </tr>
              <tr>
                <td className={styles.tgBaqh}>
                  Ir para a palestra Desbravando a Gestão do Conhecimento em
                  Engenharia de Software: Vamos nessa?
                </td>
                <td className={styles.tgBaqh}>1 selo amarelo</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>O PRÊMIO</h2>
          <div className={styles.text}>
            <div className={styles.text}>
              <div className={styles.text}>
                <p>
                  As <strong>5 primeiras pessoas</strong> a completarem o cartão
                  com os pontos vão ganhar:
                </p>
              </div>
              <div className={styles.subtext}>
                <p>
                  <strong>1 bolsa personalizada</strong>
                </p>
                <p>
                  <strong>1 bottom personalizado do projeto</strong>
                </p>
                <p>
                  <strong>1 bloquinho de anotações</strong>
                </p>
                <p>
                  <strong>1 serenata do amor.</strong>
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.titulotext}>
                <p>
                  As <strong>outras 10 primeiras pessoas</strong> vão ganhar:
                </p>
              </div>
              <div className={styles.subtext}>
                <p>
                  <strong>1 bottom personalizado do projeto</strong>
                </p>
                <p>
                  <strong>1 bloquinho de anotações</strong>
                </p>
                <p>
                  <strong>1 serenata do amor </strong>
                </p>
              </div>
            </div>
            <div className={styles.thirdBoxText}>
              <div className={styles.text}>
                <p>
                  Os outros participantes vão ganhar (limitado a 35 pessoas):{" "}
                </p>
              </div>
              <div className={styles.subtext}>
                <p>
                  <strong>1 bottom personalizado do projeto</strong>
                </p>
                <p>
                  <strong>1 serenata do amor</strong>
                </p>
              </div>
            </div>
          </div>
          <p>
            O resgate do prêmio deve ser feito na sala do projeto com a presença
            de um membro do projeto.
          </p>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>REGRAS IMPORTANTES</h2>
          <ul>
            <li>
              <strong>Promoção válida durante a SESCOMP;</strong>
            </li>
            <li>
              Só é permitido{" "}
              <strong>um resgate do prêmio por participante;</strong>
            </li>
            <li>
              O projeto não se responsabiliza pela perda do cartão ou dos
              pontos, <strong>mantenha seu cartão fidelidade seguro;</strong>
            </li>
            <li>
              Pontos de atividades só são distribuídos no{" "}
              <strong>final do minicurso/palestra.</strong>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sescomp;
