import { AxiosInstance } from 'axios';
import { dashboardWP } from '../services/api';
import { BlogPost } from '../types/Blog';

export interface PagesRepositoryInterface {
  getAllPages(): Promise<BlogPost[]>;
  getPage(id: number): Promise<any>;
}

export class PagesRepository implements PagesRepositoryInterface {
  
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getPage(id: number): Promise<any> {
    try {
      const response = await this.api.get(`pages/${id}`);
      const { data } = response;

      if (!data) {
        throw new Error('Invalid page data');
      }

      const page = data;

      return page;
    } catch (error) {
      console.error('Error getting page:', error);
      return null;
    }
  }

  async getAllPages(): Promise<BlogPost[]> {
    try {
      const response = await this.api.get<BlogPost[]>('/pages');
      const pagesData = response.data;

      if (!pagesData || !Array.isArray(pagesData)) {
        throw new Error('Invalid pages data');
      }

      const pagesPostsArray: BlogPost[] = pagesData;

      return pagesPostsArray;
    } catch (error) {
      console.error('Error getting pages posts:', error);
      return [];
    }
  }
}

export const pagesRepository = new PagesRepository(dashboardWP);
