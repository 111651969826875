import React, { CSSProperties, useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface PostCardProps {
  title: string;
  description: string;
  imageUrl: string;
  titleButton: string;
  style?: CSSProperties;
  className?: string;
}

const PostCard: React.FC<PostCardProps> = ({
  title,
  description,
  imageUrl,
  titleButton,
  style,
  className,
}) => {
    const [clientDescription, setClientDescription] = useState<string>("");

  useEffect(() => {
    setClientDescription(description);
  }, [description]);
  return (
    <div className={`${styles.card} ${className}`} style={style}>
      <img src={imageUrl} alt={title} className={styles.image} />
      <h2 className={styles.title}>{title}</h2>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: clientDescription }}
      />
      <button className={styles.button}>{titleButton}</button>
    </div>
  );
};

export default PostCard;
