import styles from "./styles.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchPage } from "../../redux/slices/pageSlice";

const Membros: React.FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const { page, error } = useSelector(
    (state: RootState) => state.pages
  );

  useEffect(() => {
    dispatch(fetchPage(289));
  }, [dispatch]);

   if (error) {
     return <div>Ocorreu um erro ao carregar os membros.</div>;
   }

  return (
    <>
      {page && (
        <>
          <header className={styles.contentContainerHeader}>
            <h1>{page.title.rendered}</h1>
          </header>
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </>
      )}
    </>
  );
};


export default Membros;
