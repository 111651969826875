import styles from "./styles.module.scss";

const Gamification: React.FC = () => {
  return (
    <>
      <section className={styles.containerGamefication}>
        <div className={styles.content}>
          <h1>GAMIFICAÇÃO NO ESTADUAL</h1>
        </div>
      </section>

      <section className={styles.introduction}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h2 className={styles.subtitle}>
              QUER GANHAR PRÊMIOS ASSISTINDO NOSSAS ATIVIDADES NO ESTADUAL?
            </h2>
            <p>Olá Learnian@!</p>
            <p>
              Queremos presentear você, Learnian@ fiel. Elaboramos um sistema de
              gameficação para premiar sua participação em nossas atividades
              durante o minicurso de Informática básica.
            </p>
            <p>
              Participe de todas as nossas atividades, para você acumular pontos
              e trocar por brindes, consulte a tabela.
            </p>
            <h2 className={styles.subtitle}>O CARTÃO FIDELIDADE</h2>
            <p>
              Antes de começar você precisa resgatar nosso cartão fidelidade.
              Você consegue pegar o cartão na nossa primeira aula. Mas atenção,
              adote apenas um cartão para facilitar na sua jornada!
            </p>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>O QUE FAZER PARA GANHAR PONTOS?</h2>
          <ul>
            <li>Seguir o LearningLab no Instagram;</li>
            <li>Postar 1 storie e marcar o projeto;</li>
            <li>Comentar o post do LearningLab no Estadual;</li>
            <li>
              Completar a dinâmica da cruzadinha com principais conceitos da
              aula;
            </li>
            <li>Realizar a atividade criação do currículo;</li>
            <li>Responder o formulário de feedback.</li>
          </ul>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <table className={styles.tg}>
            <thead>
              <tr>
                <th className={styles.tgUzvj}>ATIVIDADE</th>
                <th className={styles.tg7Btt} colSpan={3}>
                  RECOMPENSAS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.tgC3ow}>
                  Seguir o perfil oficial do LearningLab no Instagram
                </td>
                <td className={styles.tgC3ow}>1 selo rosa</td>
                <td className={styles.tg9wq8} rowSpan={3}>
                  1 selo #tôONLL <br />
                  (o resgate desse selo só ocorre com
                  <br /> a acumulação de três selos rosas)
                </td>
              </tr>
              <tr>
                <td className={styles.tgC3ow}>
                  Compartilhar um post do LearningLab nos stories e marcar o
                  projeto
                </td>
                <td className={styles.tg0Pky}>1 selo rosa</td>
              </tr>
              <tr>
                <td className={styles.tg9wq8}>
                  Comentar o post sobre o LearningLab no Estadual
                </td>
                <td className={styles.tg0Pky}>1 selo rosa</td>
              </tr>
              <tr>
                <td className={styles.tgC3ow}>
                  Completar a dinâmica da cruzadinha com principais conceitos da
                  aula
                </td>
                <td className={styles.tgC3ow}>1 selo amarelo</td>
                <td className={styles.tg9wq8} rowSpan={3}>
                  1 selo #Aventureir@LL
                  <br />
                  (o resgate desse selo só ocorre <br />
                  com a acumulação de três selos amarelos)
                </td>
              </tr>
              <tr>
                <td className={styles.tgBaqh}>
                  Fazer a atividade criação do currículo
                </td>
                <td className={styles.tgBaqh}>1 selo amarelo</td>
              </tr>
              <tr>
                <td className={styles.tgBaqh}>
                  Responder o formulário de feedback
                </td>
                <td className={styles.tgBaqh}>1 selo amarelo</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>O PRÊMIO</h2>
          <div className={styles.text}>
            <div className={styles.firstBoxText}>
              <div className={styles.subtext}>
                <p>
                  <strong>1 kit guloseima (pipoca, bombom, pirulito)</strong>
                </p>
                <p>
                  <strong>1 bottom personalizado com a logo do projeto</strong>
                </p>
                <p>
                  <strong>1 adesivo com a logo do projeto</strong>
                </p>
              </div>
            </div>
            <p>
              -&gt;O resgate do prêmio será feito após a última aula do
              minicurso de Informática básica no Estadual.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>REGRAS IMPORTANTES</h2>
          <ul>
            <li>
              <strong>
                Promoção válida durante a realização do minicurso de Informática
                básica no Estadual;
              </strong>
            </li>
            <li>
              Só é permitido{" "}
              <strong>um resgate do prêmio por participante;</strong>
            </li>
            <li>
              O projeto não se responsabiliza pela perda do cartão ou dos
              pontos, <strong>mantenha seu cartão fidelidade seguro;</strong>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Gamification;