import styles from "./styles.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchPosts } from "../../redux/slices/blogSlice";
import ContainerAboutCourses from "../../components/ContainerAboutCourses";
import CommentsContainer from "../../components/CommentsContainer";
import SectionLecture from "../../components/SectionLecture";

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { posts } = useSelector((state: RootState) => state.blog);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  return (
    <>
      <section className={styles.backgroundContainerInitial}>
        <div className={styles.containerInitial}>
          <h2>Instigando sempre sua criatividade e inovação</h2>
          <img src="/images/details.svg" alt="imagem com curiosidades" />
        </div>
      </section>
      <section className={styles.circle}> </section>
      <ContainerAboutCourses />
      <CommentsContainer />
      <SectionLecture blog={posts} />
      <div className={styles.footer}></div>
    </>
  );
};

export default Home;
