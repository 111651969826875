import { useEffect } from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchPage } from "../../redux/slices/pageSlice";

const Artigos: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { page, error } = useSelector((state: RootState) => state.pages);
  const pageId = 2;
  useEffect(() => {
    dispatch(fetchPage(pageId));
  }, [dispatch]);

  if (error) {
    return <div>Ocorreu um erro ao carregar a página.</div>;
  }
  
  return (
    <main >
      {page && (
        <>
          <div className={styles.containerHeader}>
            <h1>{page.title.rendered}</h1>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: page.content.rendered }}
          />
        </>
      )}
    </main>
  );
};

export default Artigos;
